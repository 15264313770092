import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useToggleContact } from "src/context/siteContext";

// Import component styles
import * as styles from "./contact-module.module.css";

interface ContactModuleProps {
	data: {
		contacts: [
			{
				_key: string;
				label: string;
				email?: string;
				phoneNumber?: string;
			}
		];
	};
}

const activeBarTransition = {
	type: "tween",
	ease: "circOut",
	duration: 0.4,
};

export const ContactModule = ({ data }: ContactModuleProps) => {
	const toggleContact = useToggleContact();
	const { contacts } = data;
	const [currentContact, setCurrentContact] = useState(contacts[0].label);

	const handleNavClick = (label: string) => {
		setCurrentContact(label);
	};

	return (
		<div className={styles.root}>
			<button
				className={cx("reset", styles.contactButton)}
				onClick={toggleContact}
			>
				<h2 className={cx("serif lower light", styles.title)}>Contact</h2>
			</button>
			<div className={styles.contacts}>
				<div className={styles.contactInfoContainer}>
					{contacts &&
						contacts.map((contact) => {
							return (
								<AnimatePresence exitBeforeEnter={true} key={contact._key}>
									{currentContact === contact.label && (
										<div className={cx(styles.contactInfo)}>
											<motion.div
												initial={{ y: 60 }}
												animate={{
													y: 0,
													transition: { duration: 0.4, ease: "easeInOut" },
												}}
												exit={{
													y: -60,
													transition: { duration: 0.4, ease: "easeInOut" },
												}}
											>
												{contact.phoneNumber && (
													<a
														href={`tel:${contact.phoneNumber}`}
														className={styles.contactLink}
													>
														<span
															className={cx(
																"sans sans--caps-11",
																styles.contactPhoneNumber
															)}
														>
															{contact.phoneNumber}
														</span>
													</a>
												)}
												{contact.email && (
													<a
														href={`mailto:${contact.email}`}
														className={styles.contactLink}
													>
														<span
															className={cx(
																"sans sans--caps-11",
																styles.contactEmail
															)}
														>
															{contact.email}
														</span>
													</a>
												)}
											</motion.div>
										</div>
									)}
								</AnimatePresence>
							);
						})}
				</div>
				<AnimateSharedLayout>
					<ul className={styles.nav}>
						{contacts &&
							contacts.map((contact) => {
								return (
									<li key={contact._key}>
										<button
											className={styles.navButton}
											onClick={() => handleNavClick(contact.label)}
										>
											{currentContact === contact.label && (
												<motion.div
													layoutId="bar"
													initial={false}
													transition={activeBarTransition}
													className={styles.activeBar}
												/>
											)}
											<span className={cx("sans sans--caps-11 medium")}>
												{contact.label}
											</span>
										</button>
									</li>
								);
							})}
					</ul>
				</AnimateSharedLayout>
			</div>
		</div>
	);
};
