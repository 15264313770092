import React from "react";
import cx from "classnames";

import * as styles from "./subtext.module.css";

interface SubtextProps {
	data: {
		statements: [];
		_key: string;
	};
}
export const Subtext = ({ data }: SubtextProps) => {
	const { statements, _key } = data;
	const groupCount = 4;
	const statementsStyle = {
		"--move-initial": `${-100 / groupCount}%`,
		"--move-final": `${(-100 / groupCount) * 2}%`,
	} as React.CSSProperties;

	return (
		<div className={styles.root}>
			<div className={styles.inner}>
				<div className={styles.statements} style={statementsStyle}>
					<div className={styles.statementsInner}>
						{[...Array(4)].map((item, loopIndex) => {
							return (
								<span
									className={styles.statementGroup}
									key={`${_key}_statementGroup_${loopIndex}`}
								>
									{statements &&
										statements.map((statement, statementIndex) => {
											const isEven = statementIndex % 2 === 0 ? true : false;
											return (
												<span
													key={`${_key}_${loopIndex}_statement_${statementIndex}`}
													className={cx(
														isEven ? "sans sans--30" : "serif serif--31",
														"c-dark-cream lower light",
														styles.statement
													)}
												>
													{statement}
												</span>
											);
										})}
								</span>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
