import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import cx from "classnames";
import SVG from "react-inlinesvg";
import Image from "src/components/image";
import { Reveal } from "src/components/global/reveal";
import { PageLink } from "src/components/link";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

import styles from "./intro.module.css";

export interface HomepageIntroProps {
  headline: string;
  developmentLogo: {
    asset: {
      _id: string;
      url: string;
    };
  };
  subheadline: string;
  subheadlineTag: string;
  building: {
    content: {
      main: {
        title: string;
        slug: {
          current: string;
        };
        applicationUrl: string;
      };
    };
  };
  buildingLogo: {
    asset: {
      _id: string;
      url: string;
      metadata: {
        dimensions: {
          aspectRatio: number;
        };
      };
    };
  };
  buildingIllustration: {
    asset: {
      _id: string;
      url: string;
    };
  };
}

interface HomepageIntroContent {
  content: HomepageIntroProps;
}

export const HomepageIntro = ({ content }: HomepageIntroContent) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery1 {
      sanityHeader {
        headerContent {
          incentiveHomeTablet
          incentiveHomeMobile
        }
      }
    }
  `);
  const {
    incentiveHomeTablet,
    incentiveHomeMobile
  } = data.sanityHeader.headerContent;
  const {
    headline,
    developmentLogo,
    subheadline,
    subheadlineTag,
    building,
    buildingLogo,
    buildingIllustration
  } = content;

  return (
    <Reveal style="borderBottom">
      <div className={styles.intro}>
        <div className={styles.inner}>
          <div className={styles.copy}>
            <h1
              className={cx(
                "serif serif--variable-large lower",
                styles.headlineCopy
              )}
            >
              {headline}
              <span className={cx("sans", styles.tm)}>&trade;</span>
              <Image
                className={styles.developmentLogo}
                imageId={developmentLogo.asset._id}
              />
            </h1>
            {/* <h2 className={cx("sans sans--caps-24", styles.incentive2)}>
              2 Months Free!*
            </h2> */}
            {incentiveHomeTablet && (
              <h2 className={cx(styles.incentive2)}>{incentiveHomeTablet}</h2>
            )}
            <PageLink
              to={`/availability/${building.content.main.slug.current}/all`}
            >
              <h2 className={cx("sans sans--caps-24")}>
                <span className={cx("sans sans--caps-24", styles.subheadline)}>
                  {subheadline}
                </span>
                <span className={cx("sans sans--caps-11")}>
                  {subheadlineTag}
                </span>
              </h2>
              {incentiveHomeMobile && (
                <h2 className={(cx("sans sans--caps-24"), styles.incentive)}>
                  {incentiveHomeMobile}
                </h2>
              )}
            </PageLink>
          </div>
          <div className={styles.graphics}>
            <PageLink to={getInternalLinkPath(building)}>
              <div className={styles.graphicsInner}>
                <div
                  className={styles.buildingLogoContainer}
                  style={
                    {
                      "--aspect-ratio":
                        buildingLogo?.asset?.metadata?.dimensions?.aspectRatio
                    } as React.CSSProperties
                  }
                >
                  <SVG
                    className={styles.buildingLogo}
                    src={buildingLogo.asset.url}
                    description={building.content.main.title}
                  />
                  <div className={styles.buildingIllustration}>
                    <Image imageId={buildingIllustration.asset._id} />
                  </div>
                </div>
              </div>
            </PageLink>
          </div>
        </div>
      </div>
    </Reveal>
  );
};
