export const getDateTimeParts = (dateTime: string) => {
	const date = new Date(dateTime);

	const month = new Intl.DateTimeFormat("en", {
		month: "long",
	}).format(date) as string;
	const day = new Intl.DateTimeFormat("en", {
		day: "numeric",
	}).format(date) as string;
	const weekday = new Intl.DateTimeFormat("en", {
		weekday: "long",
	}).format(date) as string;
	const year = new Intl.DateTimeFormat("en", {
		year: "numeric",
	}).format(date) as string;

	return { month, day, weekday, year };
};
