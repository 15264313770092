import React, { useState } from "react";
import cx from "classnames";
import Image from "src/components/image";
import { PageLink } from "src/components/link";
import { ReactComponent as ShopVertical } from "../../../images/svg/homepage-shop-vertical.svg";
import { ReactComponent as ShopMobile } from "../../../images/svg/homepage-shop-mobile.svg";

import * as styles from "./shop-module.module.css";

interface ShopModuleProps {
	data: {
		collections: [
			{
				_key: string;
				image: any;
				collection: any;
			}
		];
	};
}

export const ShopModule = ({ data }: ShopModuleProps) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const { collections } = data;

	const handleHover = (index: number) => {
		setCurrentIndex(index);
	};

	return (
		<div className={styles.root}>
			<div className={styles.inner}>
				<PageLink to="/shop" className={styles.shopVerticalLink}>
					<ShopVertical className={styles.shopVertical} />
				</PageLink>
				{collections && (
					<div className={styles.collections}>
						<ul className={cx("hide-small hide-medium", styles.index)}>
							{collections.map((item, index) => {
								return (
									<li className={styles.indexItem} key={item._key}>
										<PageLink
											className={cx(
												styles.indexLink,
												currentIndex === index ? styles.isActive : ""
											)}
											to="/shop"
											onMouseOver={() => handleHover(index)}
										>
											<span className={cx("sans sans--caps-11 medium")}>
												{item.collection.content.main.title}
											</span>
										</PageLink>
									</li>
								);
							})}
						</ul>
						<div className={styles.imageContainer}>
							<div className={styles.imageWrapper}>
								{collections.map((item, index) => (
									<Image
										className={cx(
											styles.image,
											currentIndex === index ? styles.isActive : ""
										)}
										imageId={item.image.asset._id}
										objectFit="cover"
										style={{
											height: "100%",
										}}
										key={item._key}
									/>
								))}
							</div>
						</div>
					</div>
				)}
				<PageLink to="/shop" className={styles.shopMobileLink}>
					<ShopMobile className={styles.shopMobile} />
				</PageLink>
			</div>
		</div>
	);
};
