import React, { useState, useEffect } from "react";
import { useSetPage } from "src/context/siteContext";
import { SEO } from "src/components/SEO";
import { RevealOnMount } from "src/components/reveal-on-mount";
import { HomepagePopup } from "src/components/homepage/popup";
import { HomepageBanner } from "src/components/homepage/banner";
import {
  HomepageIntro,
  HomepageIntroProps,
} from "src/components/homepage/intro";
import { MissionStatement } from "src/components/homepage/mission-statement";
import { BuildingModule } from "src/components/homepage/building-module";
import { AmenitiesCarousel } from "src/components/homepage/amenities-carousel";
import { Subtext } from "src/components/homepage/subtext";
import { TourModule } from "src/components/homepage/tour-module";
import { HushModule } from "src/components/homepage/hush-module";
import { EventsModule } from "src/components/homepage/events-module";
import { ShopModule } from "src/components/homepage/shop-module";
import { PressCarousel } from "src/components/homepage/press-carousel";
import { ContactModule } from "src/components/homepage/contact-module";
import { LeasingMenu } from "src/components/global/leasing-menu";

const genericModuleStyles = {
  borderBottom: "1px solid #000000",
  padding: "160px",
  display: "flex",
  justifyContent: "center",
};

const GenericModule = ({ title }: { title: string }) => {
  return (
    <div style={genericModuleStyles}>
      <h1 className="serif serif--60">{title}</h1>
    </div>
  );
};

const Modules = ({ type, data }: { type: string; data: any }) => {
  if (data.hide) {
    // if module is hidden return empty fragment
    return <></>;
  }
  switch (type) {
    case "amenitiesCarousel":
      return <AmenitiesCarousel data={data} />;
    case "missionStatement":
      return <MissionStatement data={data} />;
    case "buildingModule":
      return <BuildingModule data={data} />;
    case "subtextModule":
      return <Subtext data={data} />;
    case "shopModule":
      return <ShopModule data={data} />;
    case "pressCarousel":
      return <PressCarousel data={data} />;
    case "eventsModule":
      return <EventsModule data={data} />;
    case "contactModule":
      return <ContactModule data={data} />;
    case "staticModule":
      switch (data.module) {
        case "Tour":
          return <TourModule />;
        case "Hush":
          return <HushModule />;
        default:
          return <p>{data.module}</p>;
      }
    default:
      return <GenericModule title={type} />;
  }
};

const RenderModules = (modules: []) => {
  if (modules) {
    return modules.map((module: { _key: string; _type: string }) => {
      return <Modules key={module._key} type={module._type} data={module} />;
    });
  }
};

export interface HomepageProps {
  pageContext: {
    main: {
      slug: {
        current: string;
      };
      title: string;
      intro: HomepageIntroProps;
      modules: [];
      promoMessaging: {
        enablePopup: boolean | undefined;
        enableBanner: boolean | undefined;
        headline?: string;
        subheadline?: string;
        finePrint?: string;
      };
    };
    meta: {};
  };
  location: any;
}

const Homepage = ({ location, pageContext }: HomepageProps) => {
  const setPage = useSetPage();
  const { main, meta } = pageContext;
  useEffect(() => {
    setPage(main.slug.current);
  }, [0]);
  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} pagePath="/" />
      <div>
        {main.promoMessaging.enablePopup && (
          <HomepagePopup promoMessaging={main.promoMessaging} />
        )}

        <HomepageIntro content={main.intro} />
        <LeasingMenu
          buildingSlug={main.intro.building.content.main.slug.current}
          applicationUrl={main.intro.building.content.main.applicationUrl}
        />
        {RenderModules(main.modules)}
      </div>
    </RevealOnMount>
  );
};

export default Homepage;
