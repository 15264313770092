import React, { useState, useCallback, useEffect, useRef } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useEmblaCarousel } from "embla-carousel/react";

import { PageLink } from "src/components/link";
import { Image } from "src/components/image";

import { ReactComponent as AmenitiesSVG } from "../../../images/svg/amenities.svg";
import { ReactComponent as AmenitiesMobileSVG } from "../../../images/svg/homepage-amenities-mobile.svg";

import { ReactComponent as ArrowRight } from "../../../images/svg/arrow-right.svg";

// Import component styles
import * as styles from "./amenities-carousel.module.css";

interface PaginationButtonProps {
	index: number;
	active: boolean;
	slide: Slide;
	goToSlide: any;
}

const PaginationButton = ({
	index,
	active,
	slide,
	goToSlide,
}: PaginationButtonProps) => {
	return (
		<li className={cx(styles.paginationListItem)}>
			<button
				onClick={() => goToSlide(index)}
				className={cx(styles.paginationButton, active ? styles.isActive : "")}
			>
				<span
					className={cx(
						"sans sans--caps-11 medium",
						styles.paginationButtonTitle
					)}
				>
					{slide.title}
				</span>
			</button>
		</li>
	);
};

interface PaginationProps {
	slides: Slide[];
	goToSlide: (index: number) => void;
	selectedIndex: number;
}

const Pagination = ({ slides, goToSlide, selectedIndex }: PaginationProps) => {
	const paginationRef = useRef<HTMLDivElement>(null);

	return (
		<div
			className={cx("hide-small hide-medium", styles.pagination)}
			ref={paginationRef}
		>
			<ul className={styles.paginationList}>
				{slides.map((slide, i) => (
					<PaginationButton
						key={i}
						index={i}
						active={selectedIndex === i}
						slide={slide}
						goToSlide={goToSlide}
					/>
				))}
			</ul>
		</div>
	);
};

interface Slide {
	_key: string;
	_type: string;
	description: string;
	title: string;
	image: any;
	moreLink: boolean;
}

const Slide = ({ slide }: { slide: Slide }) => {
	return (
		<div className={cx("x y", styles.slide)} data-title={slide.title}>
			<div className={styles.slideInner}>
				{slide.image && (
					<Image
						className={styles.slideImage}
						imageId={slide.image.asset._id}
						alt={slide.title}
						style={{ height: "100%" }}
					/>
				)}
			</div>
		</div>
	);
};

interface SlideOverlaysProps {
	slides: Slide[];
	selectedIndex: number;
	buildingSlug: string;
	buildingAmenitiesCarousels: [
		{
			title: string;
		}
	];
}

const SlideOverlays = ({
	slides,
	selectedIndex,
	buildingSlug,
	buildingAmenitiesCarousels,
}: SlideOverlaysProps) => {
	return (
		<div className={cx("hide-small hide-medium", styles.slideOverlays)}>
			<div className={styles.slideOverlaysInner}>
				<AmenitiesSVG className={styles.amenitiesSvg} />
				<ArrowRight className={styles.arrowRight} />
				{slides.map((slide: Slide, i: number) => {
					const isVisible = i === selectedIndex;
					const hasBuildingAmenitiesCarousel = buildingAmenitiesCarousels.some(
						(carousel) => carousel.title === slide.title
					);

					return (
						<AnimatePresence exitBeforeEnter={true} key={i}>
							{isVisible && (
								<div className={cx(styles.slideOverlay)}>
									<div className={styles.slideOverlayTitle}>
										<motion.h3
											initial={{ y: `-25vw` }}
											animate={{
												y: 0,
												transition: { duration: 0.6, ease: "circOut" },
											}}
											exit={{
												y: `-25vw`,
												transition: { duration: 0.4, ease: "circOut" },
											}}
											className={cx("sans sans--90 lower")}
										>
											{slide.title}
										</motion.h3>
									</div>
									<div className={styles.slideOverlayDescription}>
										<motion.p
											initial={{ y: `-25vw` }}
											animate={{
												y: 0,
												transition: { duration: 0.6, ease: "circOut" },
											}}
											exit={{
												y: `-25vw`,
												transition: { duration: 0.4, ease: "circOut" },
											}}
											className="serif serif--48 lower"
										>
											{slide.description}
											{hasBuildingAmenitiesCarousel && (
												<PageLink
													className={styles.moreLink}
													to={`/buildings/${buildingSlug}#${encodeURI(
														slide.title
													)}`}
												>
													<span
														className={cx(
															"sans sans--caps-11 medium",
															styles.moreLinkCopy
														)}
													>
														MORE
													</span>
												</PageLink>
											)}
										</motion.p>
									</div>
								</div>
							)}
						</AnimatePresence>
					);
				})}
			</div>
		</div>
	);
};

interface CarouselProps {
	building: {
		content: {
			main: {
				amenitiesCarousels: [
					{
						_key: string;
						title: string;
					}
				];
				slug: {
					current: string;
				};
			};
		};
	};
	slides: Slide[];
	mobileBackground: any;
}

const Carousel = ({ building, slides, mobileBackground }: CarouselProps) => {
	const buildingSlug = building.content.main.slug.current;
	const buildingAmenitiesCarousels = building.content.main.amenitiesCarousels;

	const [viewportRef, embla] = useEmblaCarousel({
		align: "start",
	});
	const [selectedIndex, setSelectedIndex] = useState(0);

	const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
		embla,
	]);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		embla.on("select", onSelect);
		onSelect();
	}, [embla, onSelect]);

	return (
		<div className={cx("hide-small hide-medium", styles.carouselWrapper)}>
			<Pagination
				slides={slides}
				goToSlide={scrollTo}
				selectedIndex={selectedIndex}
			/>
			<SlideOverlays
				slides={slides}
				selectedIndex={selectedIndex}
				buildingSlug={buildingSlug}
				buildingAmenitiesCarousels={buildingAmenitiesCarousels}
			/>
			<div className="embla x">
				<div className="embla__viewport x" ref={viewportRef}>
					<div className={cx("embla__container x")}>
						{slides.map((slide) => {
							return <Slide key={slide._key} slide={slide} />;
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export interface AmenitiesCarouselProps {
	data: {
		building: any;
		slides: Slide[];
		mobileBackground: any;
	};
}

export const AmenitiesCarousel = ({ data }: AmenitiesCarouselProps) => {
	const { building, slides, mobileBackground } = data;
	const buildingSlug = building.content.main.slug.current;
	return (
		<>
			<div className={cx("hide-min-large", styles.amenitiesMobile)}>
				<ul className={cx(styles.amenitiesList)}>
					{slides.map((slide, i) => {
						return (
							<li className={styles.amenitiesListItem} key={slide._key}>
								<span
									className={cx(
										"sans sans--caps-11 medium",
										styles.amenitiesListItemTitle
									)}
								>
									{slide.title}
								</span>
							</li>
						);
					})}
				</ul>
				<PageLink
					className={styles.amenitiesLink}
					to={`/buildings/${buildingSlug}#amenities`}
				>
					<ArrowRight className={styles.amenitiesLinkArrow} />
					<AmenitiesMobileSVG />
				</PageLink>
				{mobileBackground &&
				mobileBackground.mp4 &&
				mobileBackground.mp4.asset ? (
					<video
						className={styles.mobileBackgroundVideo}
						preload="auto"
						playsInline={true}
						autoPlay={true}
						muted={true}
						loop={true}
					>
						<source src={mobileBackground.mp4.asset.url} type="video/mp4" />
						<Image
							className={styles.mobileBackgroundImage}
							imageId={mobileBackground.image.asset._id}
							style={{ width: "100%", height: "100%" }}
						/>
					</video>
				) : mobileBackground &&
				  mobileBackground.image &&
				  mobileBackground.image.asset ? (
					<Image
						className={styles.mobileBackgroundImage}
						imageId={mobileBackground.image.asset._id}
						style={{ width: "100%", height: "100%" }}
					/>
				) : null}
			</div>
			<Carousel
				building={building}
				slides={slides}
				mobileBackground={mobileBackground}
			/>
		</>
	);
};
