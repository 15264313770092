import React, { useState } from "react";
import cx from "classnames";
import { PageLink } from "src/components/link";
import Image from "src/components/image";
import { Reveal } from "src/components/global/reveal";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";
import { splitUnitCategory } from "src/utils/splitUnitCategory";

import { ReactComponent as ArrowRight } from "../../../images/svg/arrow-right.svg";

import styles from "./building-module.module.css";

interface BuildingModuleProps {
	data: {
		building: {
			content: {
				main: {
					slug: {
						current: string;
					};
					unitCategories: [
						{
							title: string;
							slug: {
								current: string;
							};
							_key: string;
						}
					];
				};
			};
		};
		image: any;
		linkCopy: string;
		showCommonLink: boolean;
		commonLink?: string;
	};
}
export const BuildingModule = ({ data }: BuildingModuleProps) => {
	const { building, image, linkCopy, showCommonLink, commonLink } = data;
	const buildingSlug = building.content.main.slug.current;
	const unitCategories = building.content.main.unitCategories;
	return (
		<Reveal style="borderBottom">
			<div className={styles.building}>
				<ul className={styles.availabilityMenu}>
					{unitCategories.map((unitCategory) => {
						const { title, slug } = unitCategory;
						const [serif, sans] = splitUnitCategory(title);
						return (
							<li
								key={unitCategory._key}
								className={cx(styles.availabilityMenuItem)}
							>
								<PageLink
									className={cx(
										"pink-underline-on-hover",
										styles.availabilityMenuLink
									)}
									to={`/availability/${buildingSlug}/${slug.current}`}
								>
									<span className={cx("serif lower", styles.serifVariable)}>
										{serif}
									</span>
									{sans && (
										<span className={cx("sans ml025", styles.sansVariable)}>
											{sans}
										</span>
									)}
								</PageLink>
							</li>
						);
					})}
				</ul>
				<div className={styles.imageContainer}>
					<PageLink
						className={styles.buildingLink}
						to={getInternalLinkPath(building)}
					>
						<div className={styles.buildingLinkCopyContainer}>
							<span
								className={cx(
									"sans sans--caps-11 medium",
									styles.buildingLinkCopy
								)}
							>
								{linkCopy}
							</span>
							<ArrowRight />
						</div>
						{image && (
							<Image className={styles.image} imageId={image.asset._id} />
						)}
					</PageLink>
					{showCommonLink && (
						<div className={styles.commonLinkContainer}>
							<a
								href={commonLink || ``}
								target="_blank"
								rel="noopener noreferrer"
								className={cx("sans sans--caps-11", styles.commonLink)}
							>
								<span className={cx("medium")}>
									Common
									<br />
									at the Agnes
								</span>
							</a>
						</div>
					)}
				</div>
			</div>
		</Reveal>
	);
};
