import React from "react";
import cx from "classnames";
import { useToggleContact } from "src/context/siteContext";
import { Reveal } from "src/components/global/reveal";
import { ReactComponent as MakeAnAppointmentTo } from "../../../images/svg/schedule-your-visit-to.svg";

import * as styles from "./tour-module.module.css";

export const TourModule = () => {
	const toggleContact = useToggleContact();

	return (
		<Reveal style="borderBottom">
			<div className={styles.root}>
				<button onClick={toggleContact} className={styles.button}>
					<div className={styles.inner}>
						<MakeAnAppointmentTo className={styles.makeAnAppointmentTo} />
						<span
							className={cx(
								"serif lower light",
								styles.makeAnAppointmentToMobile
							)}
						>
							Schedule your visit to
						</span>
						<h2
							className={cx("sans upper pink-underline-on-hover", styles.tour)}
						>
							Tour
						</h2>
						<span className={cx("serif lower light", styles.theSpaceMobile)}>
							The space
						</span>
						<div className={styles.theSpace}>
							<div className={styles.theSpaceInner}>
								{[...Array(4)].map((item, index) => (
									<span
										key={index}
										className={cx(
											"serif serif--variable-medium lower",
											styles.theSpaceItem
										)}
									>
										the space
									</span>
								))}
							</div>
						</div>
					</div>
				</button>
			</div>
		</Reveal>
	);
};
