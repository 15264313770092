import React, { useEffect, useState } from "react";
import cx from "classnames";
import Cookies from "js-cookie";
import { Modal, Fade } from "@material-ui/core";
import { Backdrop } from "src/components/global/backdrop";
import styles from "./popup.module.css";

import { ReactComponent as CloseX } from "src/images/svg/x.svg";

interface HomepagePopupProps {
	promoMessaging: {
		headline: string;
		subheadline: string;
		finePrint: string;
	};
}

export const HomepagePopup = ({ promoMessaging }: HomepagePopupProps) => {
	const { headline, subheadline, finePrint } = promoMessaging;

	const [open, setOpen] = useState(false);

	useEffect(() => {
		const hidePopup = Cookies.get("hidePopup");
		if (!hidePopup) {
			setOpen(true);
			Cookies.set("hidePopup", "true");
		}
	}, []);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal
			className={styles.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				active: open,
				handleClose: handleClose,
			}}
		>
			<Fade in={open}>
				<div className={styles.popup}>
					<button
						className={cx("corner-x", styles.closeButton)}
						onClick={handleClose}
					>
						<CloseX />
					</button>
					<div className={styles.inner}>
						<h2 className={cx("serif serif--150 lower", styles.headline)}>
							{headline}
						</h2>
						<h3 className={cx("serif serif--31 lower", styles.subheadline)}>
							{subheadline}
						</h3>
						<p
							className={cx("sans sans--body-11 sans--light", styles.finePrint)}
						>
							{finePrint}
						</p>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};
