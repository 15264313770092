import React, { useState, useCallback, useEffect } from "react";
import cx from "classnames";
import Image from "src/components/image";
import { useEmblaCarousel } from "embla-carousel/react";
import useInterval from "src/hooks/useInterval";

import { ReactComponent as OpenQuote } from "src/images/svg/open-quote.svg";

import * as styles from "./press-carousel.module.css";

interface PressCarouselProps {
	data: {
		slides: [
			{
				_key: string;
				logo: {
					asset: {
						_id: string;
						url: string;
					};
				};
				publication: string;
				quote: string;
			}
		];
	};
}
export const PressCarousel = ({ data }: PressCarouselProps) => {
	const { slides } = data;

	const [viewportRef, embla] = useEmblaCarousel({
		align: "start",
		loop: true,
	});

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [scrollSnaps, setScrollSnaps] = useState([]);
	const [delay, setDelay] = useState(4000);
	const [isRunning, setIsRunning] = useState(true);

	const scrollTo = useCallback((index) => embla.scrollTo(index), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

	useInterval(
		() => {
			scrollNext();
		},
		isRunning ? delay : null
	);

	const onSelect = useCallback(() => {
		if (!embla) return;
		setSelectedIndex(embla.selectedScrollSnap());
	}, [embla]);

	useEffect(() => {
		if (!embla) return;
		setScrollSnaps(embla.scrollSnapList());
		embla.on("select", onSelect);
		embla.on("pointerDown", () => {
			setIsRunning(false);
		});
		onSelect();
	}, [embla, onSelect]);

	return (
		<div className={styles.root}>
			<div className={styles.pagination}>
				{scrollSnaps.map((snap, index) => (
					<button
						className={cx(
							styles.paginationButton,
							index === selectedIndex ? styles.active : ""
						)}
						onClick={() => {
							setIsRunning(false);
							scrollTo(index);
						}}
						key={index}
					/>
				))}
			</div>
			<div className="embla embla--fade x y">
				<div className="embla__viewport x y" ref={viewportRef}>
					<div className="embla__container x y">
						{slides.map((slide, i) => (
							<div
								key={slide._key}
								className={cx("embla__slide y", styles.slide)}
							>
								<div className={styles.slideInner}>
									{slide.logo.asset && (
										<Image
											className={styles.logo}
											imageId={slide.logo.asset._id}
											alt={slide.publication}
										/>
									)}
									<blockquote className={cx("sans sans--30", styles.quote)}>
										<OpenQuote />
										{slide.quote}
									</blockquote>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
