import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import axios from "axios";
import { eventbriteConfig } from "src/utils/eventbriteConfig";
import { getDateTimeParts } from "src/utils/getDateTimeParts";

import { PageLink } from "src/components/link";

// Import component styles
import * as styles from "./events-module.module.css";

interface EventsModuleProps {
	data: {
		copy: string;
	};
}

interface NextEvent {
	name: {
		text: string;
	};
	start: {
		local: string;
	};
}

interface NextEventDate {
	weekday: string;
	day: string;
	month: string;
}

export const EventsModule = ({ data }: EventsModuleProps) => {
	const [nextEvent, setNextEvent] = useState<NextEvent | undefined>(undefined);
	const [nextEventDate, setNextEventDate] = useState<NextEventDate | undefined>(
		undefined
	);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	useEffect(() => {
		setLoading(true);
		axios
			.get(
				`${eventbriteConfig.baseURL}/organizations/${eventbriteConfig.organizationId}/events/?order_by=start_asc&status=live&page_size=1&time_filter=all&expand=venue, listing_properties`,
				{
					method: "get",
					headers: {
						Authorization: `Bearer ${eventbriteConfig.privateToken}`,
					},
				}
			)
			.then((response) => {
				const nextEventData = response.data.events[0];
				setNextEvent(nextEventData);

				const nextEventDateObject =
					nextEventData.start.local &&
					getDateTimeParts(nextEventData.start.local);
				setNextEventDate(nextEventDateObject);

				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setError(error);
			});
	}, []);

	return (
		<div className={styles.root}>
			<PageLink to="/events" className={styles.titleColumn}>
				<h2 className={cx("sans sans--130 bold upper", styles.title)}>
					Events
				</h2>
			</PageLink>
			<div className={styles.copyColumn}>
				<p className={cx("serif serif--31 lower", styles.copy)}>{data.copy}</p>
				{nextEvent && nextEventDate && (
					<PageLink to="/events" className={styles.event}>
						<span className={cx("sans sans--caps-11", styles.eventDate)}>
							{nextEventDate.weekday}, {nextEventDate.month} {nextEventDate.day}
						</span>
						<span className={cx("serif serif--14 lower", styles.eventTitle)}>
							{nextEvent.name.text}
						</span>
					</PageLink>
				)}
			</div>
		</div>
	);
};
