import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import { PageLink } from "src/components/link";
import { HushSVG } from "src/components/animations/hush-svg";

import { ReactComponent as HushModuleGraphic } from "src/images/svg/homepage-hush-module.svg";

// Import component styles
import * as styles from "./hush-module.module.css";

export const HushModule = () => {
	const hushPageCurl = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "homepage-hush-page-curl.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1200) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<PageLink to="/hush">
			<div className={styles.root}>
				<HushSVG className={styles.hushSVG} />
				<div className={styles.columnRight}>
					<div className={styles.pageCurl}>
						<Img fluid={hushPageCurl.file.childImageSharp.fluid} />
					</div>
					<HushModuleGraphic className={styles.graphic} />
				</div>
			</div>
		</PageLink>
	);
};
